import {Button, Col, Container, Form, Row} from "react-bootstrap";
import Select from "react-select";
import PhoneInput from "react-phone-input-2";
import {useEffect, useState} from "react";
import {getConfig} from "../../controller/utils";
import {axiosInstance} from "../../utils/API";

import "./shipping.style.scss"

export default function ShippingAddress({asset, utility, shipmentOption, selectShipmentOption}) {


    const [loading, setLoading] = useState(false);
    const [visible, setVisible] = useState(false);
    const [claimed, setClaimed] = useState(false);
    const [shipmentOptions, setShipmentOptions] = useState([]);

    const [mobileCountry, setMobileCountry] = useState('ae');
    const [searchTerm, setSearchTerm] = useState('');
    const [blockedCountries, setBlockedCountries] = useState([]);

    useEffect(() => {
        if(shipmentOptions && shipmentOptions.length > 0)
            selectShipmentOption(shipmentOptions[0]);
    }, [shipmentOptions])

    const [address, setAddress] = useState({
        firstName: '',
        lastName: '',
        email: '',
        street: '',
        building: '',
        city: '',
        country: '',
        postalCode: '',
        phoneNumber: ''
    });

    const handleChange = (e) => {
        const {name, value} = e.target;
        setAddress(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        setLoading(true);
        const config = getConfig();

        const payload = {
            "assetId": asset.id,
            "utilityId": utility.id,
            "firstName": address.firstName,
            "lastName": address.lastName,
            "email": address.email,
            "phoneNumber": address.phoneNumber,
            "countryName": address.country,
            "stateProvinceId": 1841,
            "address1": `${address.street} ${address.building}`,
            "address2": address.city,
            "zipPostalCode": address.postalCode
        }
        axiosInstance
            .post(`/user/claimUtility`, payload, config)
            .then((res) => {
                if (res?.data?.result?.resultData) {
                    setShipmentOptions(res.data.result.resultData.shipmentCarrierOptions);
                }
            }).catch((err) => {
            if (err?.response?.status) {
                alert(err.response?.data?.message);
            }
        }).finally((res) => {
            console.log(res);
            setLoading(false);
        });
        // Do something with the address data, like sending it to a server
        console.log(address);
    };

    useEffect(() => {
        getBlockedCountries();

    }, []);

    const handleChangePhoneCode = async (value, data, event, formattedValue) => {

        console.log("dialcode", data.dialCode);
        console.log(formattedValue)
        let phoneNumber = value
        console.log("phoneNumber", phoneNumber)

        setAddress(prevState => ({
            ...prevState,
            phoneNumber: phoneNumber
        }));
    }


    const getBlockedCountries = () => {
        const config = getConfig();
        axiosInstance
            .get(`/kyc/allowed-countries`, config)
            .then((res) => {
                console.log("Profile.edit.allowed countries", res);
                var result = Object.keys(res?.data?.result).map((key) => {
                    return {
                        value: res.data.result[key],
                        [key]: res.data.result[key],
                        label: key,
                    };
                });
                if (result?.length) setBlockedCountries(result);
            })
            .catch((err) => {
                console.log("getBlockedCountries err", err);
            });
    };

    return (

        <div className={'deliveryForm'}>

            {(!shipmentOptions || (shipmentOptions && shipmentOptions.length === 0)) ?
                <Form onSubmit={handleSubmit} aria-disabled={loading}>
                    <Container>
                        <Row>
                            <Col md={6} className={'my-2'}>
                                <Form.Group controlId={'country'}>
                                    <Form.Label>Country*</Form.Label>
                                    <Select
                                        menuPlacement="bottom"
                                        options={blockedCountries}
                                        value={searchTerm}
                                        onChange={(e) => {
                                            setSearchTerm(e);
                                            setAddress(prevState => ({
                                                ...prevState,
                                                country: e.label
                                            }));
                                            setMobileCountry(e.value.toLowerCase())
                                        }}
                                        styles={{
                                            input: (provided) => ({
                                                ...provided,
                                                color: '#FFF'
                                            }),
                                            option: (provided, state) => ({
                                                ...provided,
                                                color: 'white', // Change font color of options based on focus state,
                                                backgroundColor: '#3e2f5f'
                                            }),
                                            singleValue: (provided, state) => ({
                                                ...provided,
                                                color: 'white'
                                            }),
                                            control: (baseStyles, state) => ({
                                                ...baseStyles,
                                                backgroundColor: '#3e2f5f',
                                                borderColor: '#FFF',
                                                color: 'white'
                                            }),
                                        }}
                                    />
                                </Form.Group>
                            </Col>
                            <Col md={6} className={'my-2'}>
                                <Form.Group controlId="street">
                                    <Form.Label>Phone Number*</Form.Label>
                                    <PhoneInput
                                        enableSearch={true}
                                        country={mobileCountry}
                                        value={address.phoneNumber}
                                        onChange={handleChangePhoneCode}
                                    />
                                </Form.Group>
                            </Col>

                        </Row>
                        <Row>
                            <Col md={6} className={'my-2'}>
                                <Form.Group controlId="firstName">
                                    <Form.Label>First Name*</Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder="Enter first name"
                                        name="firstName"
                                        value={address.firstName}
                                        onChange={handleChange}
                                        required
                                    />
                                </Form.Group>
                            </Col>
                            <Col md={6} className={'my-2'}>
                                <Form.Group controlId="lastName">
                                    <Form.Label>Last Name*</Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder="Enter last name"
                                        name="lastName"
                                        value={address.lastName}
                                        onChange={handleChange}
                                        required
                                    />
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={6} className={'my-2'}>
                                <Form.Group controlId="email">
                                    <Form.Label>Email Address*</Form.Label>
                                    <Form.Control
                                        type="email"
                                        placeholder="Enter email address"
                                        name="email"
                                        value={address.email}
                                        onChange={handleChange}
                                        required
                                    />
                                </Form.Group>
                            </Col>
                            <Col md={6} className={'my-2'}>
                                <Form.Group controlId="city">
                                    <Form.Label>City*</Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder="Enter city"
                                        name="city"
                                        value={address.city}
                                        onChange={handleChange}
                                        required
                                    />
                                </Form.Group>
                            </Col>

                        </Row>

                        <Row>
                            <Col md={6} className={'my-2'}>
                                <Form.Group controlId="street">
                                    <Form.Label>Street Address*</Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder="Enter street address"
                                        name="street"
                                        value={address.street}
                                        onChange={handleChange}
                                        required
                                    />
                                </Form.Group>
                            </Col>
                            <Col md={6} className={'my-2'}>
                                <Form.Group controlId="building">
                                    <Form.Label>Building Name / Number*</Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder="Enter building name and number "
                                        name="building"
                                        value={address.building}
                                        onChange={handleChange}
                                        required
                                    />
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col className={'m-0 mt-3'}>
                                <Button variant="primary" type="submit" className={'claimButton submit'}
                                        disabled={loading}>
                                    {loading ? 'Saving...' : 'Save address'}
                                </Button>
                            </Col>
                        </Row>
                    </Container>
                </Form>
                : (
                    <>
                        <h4>Selected Address</h4>
                        {address.firstName} {address.lastName}<br/>
                        {address.street} {address.building}<br/>
                        {address.city}, {address.country}<br/>
                        Phone number: {address.phoneNumber}<br/><Button variant="primary" onClick={() => {
                            setShipmentOptions([]);
                            selectShipmentOption(null);
                    }}>Edit Address</Button>
                        <br/><br/>
                        <h4>Select Courier</h4>
                        {shipmentOptions.map((sh) => {
                            return <div>
                                <h5><input checked={shipmentOption === sh} onClick={() => selectShipmentOption(sh)} value={shipmentOption} type={'radio'} name={'shipmentOption'} /> {sh.Name} - <small>{sh.Price}</small></h5>
                                <p>{sh.MethodName} </p>
                            </div>
                        })}

                    </>
                )
            }
        </div>

    )
}
