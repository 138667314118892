import { useState, useEffect } from "react";
import styles from "./dash.module.sass";
import DashCardView from "./DashCardView";
import queryString from "query-string";
import { useLocation } from "react-router-dom";
import { useHistory } from "react-router-dom";
import OrderDetails from "../../components/OrderDetails/OrderDetails";
import DashAuctionTab from "./DashAuctionTab";
import ResellItem from "../userItem/ResellItem";
import UserCommissionTable from "./userCommissionTable";
import CreatedNfts from "./CreatedNfts";
import { useSelector } from "react-redux";

import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import Collections from "../../components/Collections";
import RequestedNFTs from "../../components/RequestedNft/RequestedNFTs";
import ProfileEdit from "../ProfileEdit";
import Useraccount from "../../components/UserAccount/userAccount";
import WishListDetails from "../Wishlist/WishListDetails";
import Wishlist from "../Wishlist/WishListDetails";
import CirclePayment from "../CirclePayment";
import Earnings from "../Earnings";
import Analytics from "../Analytics";

const DashTabs = () => {
  const [menuIndex, setMenuIndex] = useState(0);
  const [auctionMenuIndex, setAuctionMenuIndex] = useState(0);
  const [createdMenuIndex, setCreatedMenuIndex] = useState(0);
  const [page, setPage] = useState(1);
  const { search } = useLocation();
  const history = useHistory();
  const loginAccountId = useSelector((state) => state?.accountReducer?.id);
  const isCreator = useSelector(
    (state) => state?.accountReducer?.isCreatedByAdmin
  );

  const menuItems = [
    {
      title: "Inventory",
      id: 1,
      isCreatedByAdmin: isCreator,
    },
    {
      title: "Collections",
      id: 6,
      isCreatedByAdmin: isCreator,
    },
    {
      title: "Commissions",
      id: 5,
      isCreatedByAdmin: isCreator,
    },
    {
      title: "Analytics",
      id: 14,
      isCreatedByAdmin: isCreator,
    },
    {
      title: "Orders",
      id: 0,
    },
    {
      title: "Listings",
      id: 4,
      isCreatedByAdmin: isCreator,
    },
    // {
    //   title: "Bids",
    //   id: 2,
    //
    // },
    // {
    //   title: "Claimed Assets",
    //   id: 9,
    // },
    // {
    //   title: "Transactions",
    //   id: 3,
    // },

    {
      title: "Wishlist",
      id: 11,
    },
    {
      title: "Earnings",
      id: 13,
      isCreatedByAdmin: isCreator,
    },
    {
      title: "Wallet",
      id: 12,
    },

    {
      title: "Edit Profile",
      id: 7,
    }
  ];

  const auctionMenuItems = [
    {
      title: "Bids active",
      id: 0,
    },
    {
      title: "Bids won",
      id: 1,
    },
    {
      title: "Bids lost",
      id: 2,
    },
  ];

  const createdNFTSubItems = [
    {
      title: "For Sale",
      id: 0,
    },
    {
      title: "Sold",
      id: 1,
    },
  ];

  // page back handle using url parameter
  useEffect(() => {
    const urlParam = queryString.parse(search);
    if (urlParam?.tab) {
      setMenuIndex(Number(urlParam?.tab));
    }
    if (urlParam?.atab) {
      setAuctionMenuIndex(Number(urlParam?.atab));
    }

    if(!urlParam?.tab && isCreator) {
      setMenuIndex(1)
    }
  }, [search, isCreator]);


  return (
    <>
      <div className={styles.menuWrapper}>
        {menuItems?.map((item, index) => (
          <div
            style={{ display: item.isCreatedByAdmin === false ? "none" : "" }}
            className={
              menuIndex == item?.id ? styles.selected : styles.nonSelected
            }
            onClick={() => {
              setMenuIndex(item.id);
              setPage(1);
              history.push(`/dashboard?tab=${item?.id}`);
            }}
          >
            {item.title}
          </div>
        ))}
      </div>
      {/* for created tabs */}
      {menuIndex == 1 && (
        <div className={styles.subMenuWrapper}>
          {createdNFTSubItems?.map((item, index) => (
            <div
              className={
                createdMenuIndex == item?.id
                  ? styles.subSelected
                  : styles.subNonSelected
              }
              onClick={() => {
                setCreatedMenuIndex(item.id);
                setPage(1);
                history.push(`/dashboard?tab=1&ctab=${item?.id}`);
              }}
              key={index}
            >
              {item.title}
            </div>
          ))}
        </div>
      )}

      {/* For Auction tabs */}
      {menuIndex == 2 && (
        <div className={styles.subMenuWrapper}>
          {auctionMenuItems?.map((item, index) => (
            <div
              className={
                auctionMenuIndex == item?.id
                  ? styles.subSelected
                  : styles.subNonSelected
              }
              onClick={() => {
                setAuctionMenuIndex(item.id);
                setPage(1);
                history.push(`/dashboard?tab=2&atab=${item?.id}`);
              }}
              key={index}
            >
              {item.title}
            </div>
          ))}
        </div>
      )}

      {menuIndex === 0 && (
        <DashCardView id={menuIndex} page={page} setPage={setPage} />
      )}
      {menuIndex == 1 && (
        <CreatedNfts
          id={menuIndex}
          createdMenuIndex={createdMenuIndex}
          page={page}
          setPage={setPage}
        />
      )}
      {menuIndex === 2 && (
        <DashAuctionTab
          id={menuIndex}
          auctionTabId={auctionMenuIndex}
          page={page}
          setPage={setPage}
        />
      )}
      {menuIndex === 9 && <RequestedNFTs sourcePage={"myProfile"} />}
      {menuIndex === 3 && <OrderDetails sourcePage={"myProfile"} />}
      {menuIndex === 4 && <ResellItem />}
      {menuIndex === 5 && <UserCommissionTable />}
      {menuIndex === 6 && <Collections />}
      {menuIndex === 7 && <Useraccount/>}
      {menuIndex === 11 && <Wishlist />}
      {menuIndex === 12 && <CirclePayment isCreator={isCreator}/>}
      {menuIndex === 13 && <Earnings />}
      {menuIndex === 14 && <Analytics />}
      {/* <DashCardView id={menuIndex} auctionTabId={auctionMenuIndex} page={page} setPage={setPage}/> */}
    </>
  );
};

export default DashTabs;
