
import './style.scss';
import {Col, Row} from "react-bootstrap";
import moment from "moment";

export default function Order({order}){

    const shipping = order.assetUtilities && order.assetUtilities.length > 0 && order.assetUtilities[0].utilityRequest.length > 0 ? order.assetUtilities[0].utilityRequest[0] : {};
    console.log(shipping);
    const address = shipping && shipping.dropOffAddress ? shipping.dropOffAddress : {};

    return (
        <div className={'orderRow p-4 mb-4'}>
            <Row>
                <Col xs={3}>
                    <img src={order?.thumbnailUrl || order?.mediaPreviewUrl} className={'img-fluid orderImg'}/>
                </Col>
                <Col className={'text-white'} md={7}>
                    ORDER #{order.id} - Placed On {moment(order.createdAt).format('ddd DD MMMM YYYY h:mm:ss A')}<br/><br/>
                    <h3>{order.name}</h3>
                    <h5><br/>Shipping By {shipping?.shippingOption?.description}</h5>
                    <p>
                        {address.firstName} {address.lastName}<br/>
                        {address.address1}<br/>
                        {address.address2}, {address.countryName}
                    </p>
                </Col>
                <Col className={'text-white text-end'}>
                    <h6>Total</h6>
                    <h3>${order.salePrice + parseFloat((shipping?.shippingOption?.usdRate?.toFixed(2)))}</h3>
                    <p>&nbsp;</p>
                    <h6>Shipping status</h6>
                    <h4>{shipping.status}</h4>
                </Col>
            </Row>
        </div>
    )

}
